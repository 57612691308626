<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
        <v-card :min-height="$vuetify.breakpoint.mobile?'500px':'600px'">
          <router-link :to="{ name: card.link}" style="text-decoration: none">
            <v-img eager :src="require(`@/assets/menu-images/${card.imgPath}`)" class="hover-img"
                   gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                   :height="$vuetify.breakpoint.mobile?'350px':'600px'">
              <v-card-title v-text="card.title" class="hover-img-title"></v-card-title>
            </v-img>
          </router-link>
          <v-card-text class="subtitle-1">{{$t(card.desc)}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" block @click="$router.push({ name: card.link })">
              {{$t(card.title)}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "agency-menu",
    data() {
      return {
        cards: [
          {
            title: this.$t('create'),
            imgPath: 'new-agency.jpg',
            flex: 6,
            link: 'Create Agency',
            desc: 'create-agency-descr'
          },
          {
            title: this.$t('view'),
            imgPath: 'view-agencies.jpg',
            flex: 6,
            link: 'All Agencies',
            desc: 'all-agencies-descr'
          },
        ],
      }
    },
  }
</script>

<style>
  .hover-img {
    transition: all 0.2s ease;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    border-radius: 5px 5px 0 0;
  }

  .hover-img-title {
    font-weight: 600;
    justify-items: center;
    text-transform: uppercase;
    font-size: 2.4rem;
    justify-content: center;
    color: white;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .hover-img:hover {
    transform: scale(1.01);
    opacity: 0.9;
  }

  @media (max-width: 450px){
    .hover-img-title {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 400px){
    .hover-img-title {
      font-size: 1.2rem;
    }
  }
</style>
